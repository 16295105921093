<template>
    <div class="d-flex flex-column mr-2 ml-2">
        <h2 class="text-center">
            INVOICE # {{ invoice.id }}
            <psi-copy-button
                v-if="mode !== 'print'"
                small
                :value="invoice.id"
                label="Invoice #"
            ></psi-copy-button>
        </h2>
        <v-divider class="my-2"></v-divider>

        <div class="d-flex ml-2">
            <v-icon class="mr-2 primary--text">mdi-calendar</v-icon>
            <span style="width: 70px">Sent Date: </span>
            <span class="text-subtitle-2 ml-2">{{
                $_.get(invoice, "sent_date", "")
            }}</span>
        </div>
        <div class="d-flex mt-2 ml-2">
            <v-icon class="mr-2 primary--text">mdi-calendar</v-icon>
            <span style="width: 70px">Due Date: </span>
            <span class="text-subtitle-2 ml-2">{{
                $_.get(invoice, "due_date", "")
            }}</span>
        </div>
        <v-divider class="my-2"></v-divider>
        <div class="d-flex mt-2 ml-2">
            <v-icon class="mr-2 primary--text">mdi-clipboard-account</v-icon>
            <span style="width: 70px">Orders: </span>
            <v-chip small color="secondary">{{
                $_.get(invoice, "orders_count", "")
            }}</v-chip>
        </div>
    </div>
</template>
<script>
export default {
    name: "invoice-order-detail",
    components: {},
    props: {
        invoice: {
            type: Object,
            required: true,
        },
        mode: {
            type: String,
            required: false,
            default: "screen",
        },
    },
    data() {
        return {};
    },
};
</script>

<style scoped>
</style>