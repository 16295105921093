var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    { staticClass: "d-flex flex-column mr-2 ml-2" },
    [
      _c(
        "h2",
        { staticClass: "text-center" },
        [
          _vm._v(" INVOICE # " + _vm._s(_vm.invoice.id) + " "),
          _vm.mode !== "print"
            ? _c("psi-copy-button", {
                attrs: { small: "", value: _vm.invoice.id, label: "Invoice #" }
              })
            : _vm._e()
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-2" }),
      _c(
        "div",
        { staticClass: "d-flex ml-2" },
        [
          _c("v-icon", { staticClass: "mr-2 primary--text" }, [
            _vm._v("mdi-calendar")
          ]),
          _c("span", { staticStyle: { width: "70px" } }, [
            _vm._v("Sent Date: ")
          ]),
          _c("span", { staticClass: "text-subtitle-2 ml-2" }, [
            _vm._v(_vm._s(_vm.$_.get(_vm.invoice, "sent_date", "")))
          ])
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "d-flex mt-2 ml-2" },
        [
          _c("v-icon", { staticClass: "mr-2 primary--text" }, [
            _vm._v("mdi-calendar")
          ]),
          _c("span", { staticStyle: { width: "70px" } }, [
            _vm._v("Due Date: ")
          ]),
          _c("span", { staticClass: "text-subtitle-2 ml-2" }, [
            _vm._v(_vm._s(_vm.$_.get(_vm.invoice, "due_date", "")))
          ])
        ],
        1
      ),
      _c("v-divider", { staticClass: "my-2" }),
      _c(
        "div",
        { staticClass: "d-flex mt-2 ml-2" },
        [
          _c("v-icon", { staticClass: "mr-2 primary--text" }, [
            _vm._v("mdi-clipboard-account")
          ]),
          _c("span", { staticStyle: { width: "70px" } }, [_vm._v("Orders: ")]),
          _c("v-chip", { attrs: { small: "", color: "secondary" } }, [
            _vm._v(_vm._s(_vm.$_.get(_vm.invoice, "orders_count", "")))
          ])
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }